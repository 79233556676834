import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { Button, Heading, Tag } from '../Atoms';
import { Box, Flex, Text } from 'rebass';
import EyeTheme from './wrapper';
import Theme from './theme';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "atoms"
    }}>{`Atoms`}</h1>
    <p>{`Atoms are the smallest possible UI components in our design system. They include correspondents with HTML elements, such as buttons, headlines and form elements. Many atoms are used to build an application, but only the ones which we customize are listed here. `}</p>
    <h2 {...{
      "id": "button"
    }}>{`Button`}</h2>
    <Playground __position={0} __code={'<Flex>\n  <Box width={1 / 4}>\n    <Button>Default</Button>\n  </Box>\n  <Box width={1 / 4}>\n    <Button primary>Primary</Button>\n  </Box>\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Button,
      Heading,
      Tag,
      Box,
      Flex,
      Text,
      EyeTheme,
      Theme
    }} mdxType="Playground">
    <Flex mdxType="Flex">
        <Box width={1 / 4} mdxType="Box">
            <Button mdxType="Button">Default</Button>
        </Box>
        <Box width={1 / 4} mdxType="Box">
            <Button primary mdxType="Button">Primary</Button>
        </Box>
    </Flex>
    </Playground>
    <p><strong parentName="p">{`When to use:`}</strong>{` `}</p>
    <ul>
      <li parentName="ul">{`Important call to action, such as for ticket sales`}</li>
      <li parentName="ul">{`Only one primary should exist on a page`}</li>
      <li parentName="ul">{`The button should be expected in the given context`}</li>
    </ul>
    <h2 {...{
      "id": "headline"
    }}>{`Headline`}</h2>
    <Playground __position={1} __code={'<Heading as=\"h1\" size=\"h1\">\n  h1: Eye Filmmuseum\n</Heading>\n<Heading as=\"h1\" size=\"h2\">\n  h2: Sized h2, tagged h1\n</Heading>\n<Heading as=\"h3\">h3: Chocolate and coffee</Heading>\n<Heading as=\"h4\">h4: Films and popcorn</Heading>\n<Heading as=\"h5\">h5: Amsterdam, NL</Heading>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Button,
      Heading,
      Tag,
      Box,
      Flex,
      Text,
      EyeTheme,
      Theme
    }} mdxType="Playground">
    <Heading as="h1" size="h1" mdxType="Heading">h1: Eye Filmmuseum</Heading>
    <Heading as="h1" size="h2" mdxType="Heading">h2: Sized h2, tagged h1</Heading>
    <Heading as="h3" mdxType="Heading">h3: Chocolate and coffee</Heading>
    <Heading as="h4" mdxType="Heading">h4: Films and popcorn</Heading>
    <Heading as="h5" mdxType="Heading">h5: Amsterdam, NL</Heading>
    </Playground>
    <h2 {...{
      "id": "tag"
    }}>{`Tag`}</h2>
    <Playground __position={2} __code={'<Tag invert mr={3}>\n  Now showing\n</Tag>\n<Tag mr={3}>Man/Machine</Tag>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Button,
      Heading,
      Tag,
      Box,
      Flex,
      Text,
      EyeTheme,
      Theme
    }} mdxType="Playground">
    <Tag invert mr={3} mdxType="Tag">Now showing</Tag>
    <Tag mr={3} mdxType="Tag">Man/Machine</Tag>
    </Playground>
    <h2 {...{
      "id": "lists"
    }}>{`Lists`}</h2>
    <Playground __position={3} __code={'<Flex flexDirection=\"column\">\n  <ul>\n    <li>Unordered list</li>\n    <li>Quo ex quando reprimique dissentiunt</li>\n    <li>Another short line</li>\n  </ul>\n  <ol>\n    <li>Unordered list</li>\n    <li>Quo ex quando reprimique dissentiunt</li>\n    <li>Another short line</li>\n  </ol>\n</Flex>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Button,
      Heading,
      Tag,
      Box,
      Flex,
      Text,
      EyeTheme,
      Theme
    }} mdxType="Playground">
    <Flex flexDirection="column" mdxType="Flex">
        <ul>
            <li>Unordered list</li>
            <li>Quo ex quando reprimique dissentiunt</li>
            <li>Another short line</li>
        </ul>
        <ol>
            <li>Unordered list</li>
            <li>Quo ex quando reprimique dissentiunt</li>
            <li>Another short line</li>
        </ol>
    </Flex>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      